import React from 'react';
import * as R from 'ramda'
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceInput, SelectInput, ImageInput, ImageField, NumberInput, AutocompleteInput } from 'react-admin';
import { required } from '../../services/validate'
import Geosuggest from 'react-geosuggest';
import { compose, withProps } from 'recompose';
import { useForm } from 'react-final-form';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { findAddress, get_province } from '../../services/address/finder';
import '../places/GooglePlacesComplete.css'

const provinces = get_province().map(p => ({ value: p.province_code, label: p.province_th }))
let maps;

const GeosuggestForm = ({ formData, ...rest }) => {
    const form = useForm();
    return (
        <React.Fragment>
            <Geosuggest onSuggestSelect={(obj) => {
                if (!!maps) {
                    if (obj) {
                        form.change('position', obj.location)
                        form.change('lat', obj.location.lat)
                        form.change('long', obj.location.lng)
                        maps.panTo(obj.location)
                    }
                }
            }} />
        </React.Fragment>
    );
};

const MapForm = ({ formData, ...rest }) => {
    const form = useForm();
    formData.position = { lat: formData.lat, lng: formData.long }
    const maker = !!formData.position && formData.position.lat && formData.position.lng ?
        [{ position: { ...formData.position } }] : []
    if (maps && maker.length > 0) maps.panTo({ ...formData.position })
    return (
        <React.Fragment>
            <Map isMarkerShown
                markers={maker}
                setRefMap={(map) => {
                    maps = map;
                    if (maps && maker.length > 0) maps.panTo({ ...formData.position })
                }}
                onMapClick={(event) => {
                    let position = { lat: event.latLng.lat(), lng: event.latLng.lng() }
                    form.change('position', position)
                    form.change('lat', position.lat)
                    form.change('long', position.lng)
                }} />
        </React.Fragment>
    );
};
const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        {!props.isCreate && <TextInput source="app_id" label="App ID" fullWidth validate={[required()]} />}
        <TextInput source="name" label="Name" fullWidth validate={[required()]} />
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return <Area formData={formData} />
            }}
        </FormDataConsumer>
        <FormDataConsumer>
            {formDataProps => {
                return <GeosuggestForm {...formDataProps} />
            }}
        </FormDataConsumer>
        <NumberInput source="lat" style={{ marginRight: 20, float: 'left' }} />
        <NumberInput source="long" />
        <FormDataConsumer>
            {formDataProps => {
                return <MapForm {...formDataProps} />
            }}
        </FormDataConsumer>
        <TextInput source="tel" label="Tel" fullWidth validate={[required()]} />
        <TextInput source="bank_acc" label="Bank acc" fullWidth validate={[required()]} />
        <ImageInput source="bank_acc_file" label="Bank acc file" accept="image/*" >
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
        <ImageInput source="attachs" label="Attachs" accept="image/*">
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
        <BooleanInput label="Proved" source="proved" />
    </SimpleForm>
);
const Area = props => {
    const { formData } = props;
    const districts = formData.province ? findAddress({ province_code: formData.province }) : []
    const sub_districts = formData.district ? findAddress({ amphoe_code: formData.district }) : []
    const zipcode = formData.sub_district ? findAddress({ district_code: formData.sub_district }) : []
    const districtOptions = formData.province
        ? R.unionWith(R.eqBy(R.prop('amphoe_code')), districts, []).map(address => ({
            value: address.amphoe_code,
            label: address.amphoe_th,
        }))
        : [];
    const SubDistrictOptions = formData.district
        ? R.unionWith(R.eqBy(R.prop('district_code')), sub_districts, []).map(address => ({
            value: address.district_code,
            label: address.district_th,
        }))
        : [];
    const ZipCodeOptions = formData.sub_district
        ? R.unionWith(R.eqBy(R.prop('zipcode')), zipcode, []).map(address => ({
            value: address.zipcode,
            label: `${address.zipcode}`,
        }))
        : [];
    return (
        <div>
            <TextInput source="address" label="Address" fullWidth validate={[required()]} />
            <AutocompleteInput source={`province`} label="Province" choices={provinces} optionText="label" optionValue="value" fullWidth validate={[required()]} />
            <AutocompleteInput source="district" label="District" choices={districtOptions} optionText="label" optionValue="value" fullWidth validate={[required()]} />
            <AutocompleteInput source="sub_district" label="Subdistrict" choices={SubDistrictOptions} optionText="label" optionValue="value" fullWidth validate={[required()]} />
            <AutocompleteInput source="zipcode" label="Zipcode" choices={ZipCodeOptions} optionText="label" optionValue="value" fullWidth validate={[required()]} />
        </div>
    );
};

const Map = compose(
    withProps({
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap
)(({ markers, ...props }) => {
    return (
        <GoogleMap
            ref={props.setRefMap}
            defaultZoom={18}
            defaultCenter={markers.length > 0 ? { ...markers[0].position } : { lat: 13.7563309, lng: 100.50176510000006 }}
            onClick={props.onMapClick}>
            {markers.map((marker, index) => (
                <Marker key={index} {...marker} />
            ))}
        </GoogleMap>
    )
});
export default Form;