import React from 'react';
import { List, Datagrid, TextField, TextInput, ReferenceField, ReferenceInput, SelectInput, Filter } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Company name" source="provider.company_name_contains" />
        <ReferenceInput source="mainservice" reference="mainservices">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
    </Filter>
)

const mgmtServiceList = props => (
    <List {...props} filters={<ListFilter />}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Provider" source="prov_id._id" reference="providers">
                <TextField source="company_name" />
            </ReferenceField>
            <ReferenceField label="Mainservice" source="mainservice._id" reference="mainservices">
                <TextField source="name_th" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export default mgmtServiceList