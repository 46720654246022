import React from 'react';
import { List, Datagrid, Filter, AutocompleteInput, EditButton, FunctionField, NumberField } from 'react-admin';
import { findAddress, get_province } from '../../services/address/finder';

const provinces = get_province().map(p => ({ value: p.province_code, label: p.province_th }))

const ListFilter = (props) => (
    <Filter {...props}>
        <AutocompleteInput source="province" choices={provinces} optionText="label" optionValue="value" />
    </Filter>
)

const ExtraProvinceList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <FunctionField label="Province" render={record => {
                const address = findAddress({ province_code: record.province });
                return `${address[0] ? address[0].province_th : record.province}`
            }}
            />
            <NumberField label="Extra" source="extra" options={{ style: 'currency', currency: 'THB' }} />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default ExtraProvinceList