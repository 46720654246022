import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export class ModalActions extends React.Component {

    render() {
        const {
            title,
            content,
        } = this.props;
        return (
            <React.Fragment>
                <Dialog
                    aria-labelledby="draggable-dialog-title"
                    open={this.props.open}
                    onClose={this.props.onClose}
                >
                    <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
                    <DialogContent dividers>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus color="primary" onClick={this.props.onClose}>
                            Cancel</Button>
                        <Button color="primary" onClick={this.props.onOk}>
                            Ok</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}