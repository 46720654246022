import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
} from "react-admin";

const MgmtHowToBuyProviderList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <ReferenceField
        label="Provider"
        source="provider.id"
        reference="providers"
      >
        <TextField source="company_name" />
      </ReferenceField>
      {/* <TextField label="Max order" source="max_order" /> */}
      <TextField label="Max item per order" source="max_item_per_order" />
      <TextField label="Max qty per item" source="max_qty_per_item" />
      <EditButton />
    </Datagrid>
  </List>
);

export default MgmtHowToBuyProviderList;
