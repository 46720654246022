import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, BooleanField, BooleanInput, EditButton, ChipField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Shipping type" source="name_th_contains" />
        <BooleanInput label="Visible" source="visible" />
    </Filter>
)

const ShippingTypeList = props => (
    <List {...props} filters={<ListFilter />} >
        <Datagrid rowClick="edit">
            <ChipField label="Shipping type" source="name_th" />
            <BooleanField label="Visible" source="visible" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ShippingTypeList