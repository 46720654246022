import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, FunctionField, AutocompleteInput, EditButton } from 'react-admin';
import { findAddress, get_province } from '../../services/address/finder';

const provinces = get_province().map(p => ({ value: p.province_code, label: p.province_th }))

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name_contains" />
        <AutocompleteInput source="province" choices={provinces} optionText="label" optionValue="value" />

    </Filter>
)
const InternalAppList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Name" source="name" />
            <TextField label="Tel" source="tel" />
            <TextField label="Address" source="address" />
            <FunctionField label="Province" render={record => {
                const address = findAddress({ province_code: record.province });
                return `${address[0] ? address[0].province_th : record.province}`
            }}
            />
            <FunctionField label="District" render={record => {
                const address = findAddress({ amphoe_code: record.district });
                return `${address[0] ? address[0].amphoe_th : record.district}`
            }}
            />
            <EditButton />
        </Datagrid>
    </List>
);

export default InternalAppList