import React from 'react';
import { Edit, Toolbar, SaveButton } from 'react-admin';
import Form from './Form'

export const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const CornRefundEdit = (props) => (
    <Edit {...props}>
        <Form toolbar={<CustomToolbar />} />
    </Edit>
);

export default CornRefundEdit 