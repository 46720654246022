import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

const MgmtHowToBuyList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      {/* <TextField label="Max order" source="max_order" /> */}
      <TextField label="Max item per order" source="max_item_per_order" />
      <TextField label="Max qty per item" source="max_qty_per_item" />
      <EditButton />
    </Datagrid>
  </List>
);

export default MgmtHowToBuyList;
