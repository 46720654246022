import React from 'react';
import * as R from 'ramda'
import { SimpleForm, TextInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput multiline source="legal_th" label="Legal TH" fullWidth validate={[required()]} />
        <TextInput multiline source="legal_en" label="Legal EN" fullWidth validate={[required()]} />
        <TextInput multiline source="legal_my" label="Legal MY" fullWidth validate={[required()]} />
        <TextInput multiline source="term_and_condition_en" label="Term and condition TH" fullWidth validate={[required()]} />
        <TextInput multiline source="term_and_condition_th" label="Term and condition EN" fullWidth validate={[required()]} />
        <TextInput multiline source="term_and_condition_my" label="Term and condition MY" fullWidth validate={[required()]} />
    </SimpleForm>
);

export default Form;