import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, FunctionField, EditButton, ReferenceArrayField, SingleFieldList, ChipField, ArrayField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Day" source="day_th_contains" />
    </Filter>
)

const GarageList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Id" source="id" />
            <TextField label="Provider code" source="provider_code" />
            <ArrayField source="services">
                <SingleFieldList>
                    <ChipField source="name_th" />
                </SingleFieldList>
            </ArrayField>
            <FunctionField label="Lat/Lng" render={record => <a target="_blank" href={`https://www.google.co.th/maps?q=${record.location.coordinates[1]},${record.location.coordinates[0]}`}>{`${record.location.coordinates[1]} ${record.location.coordinates[0]}`}</a>} />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default GarageList