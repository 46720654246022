import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, ReferenceInput, ReferenceField, SelectInput, ChipField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Company name" source="prov_id.company_name_contains" />
        <ReferenceInput label="Provider" source="prov_id._id" reference="providers">
            <SelectInput optionText="company_name" />
        </ReferenceInput>
        <ReferenceInput label="Food type" source="food_type._id" reference="foodtypes">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
    </Filter>
)

const FoodTagList = props => (
    <List {...props} filters={<ListFilter />} >
        <Datagrid rowClick="edit">
            <ReferenceField label="Provider" source="prov_id._id" reference="providers">
                <TextField source="company_name" />
            </ReferenceField>
            <ChipField label="Food type" source="food_type.name_th" />
            <EditButton />
        </Datagrid>
    </List>
);

export default FoodTagList