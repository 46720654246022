import React from 'react';
import { SimpleForm, TextInput, BooleanInput, NumberInput, ImageInput, ImageField, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';
import { required, requiredMin } from '../../services/validate'
import { DateTimeInput } from 'react-admin-date-inputs';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        {props.isCreate &&
            <React.Fragment>
                <NumberInput source="quantity" label="Quantity" fullWidth validate={[required()]} />
                <NumberInput source="char_length" label="Character length" fullWidth validate={[requiredMin(8)]} />
                <TextInput source="prefix" label="Prefix" fullWidth />
                <TextInput source="suffix" label="Suffix" fullWidth />
            </React.Fragment>
        }
        <NumberInput source="price" label="Price" fullWidth validate={[required()]} />
        <NumberInput source="wallet" label="Wallet" fullWidth validate={[required()]} />
        <DateTimeInput source="start_date" label="Start date" fullWidth options={{ format: 'dd/MM/yyy, HH:mm:ss', ampm: false, clearable: true }} />
        <DateTimeInput source="end_date" label="End date" fullWidth options={{ format: 'dd/MM/yyy, HH:mm:ss', ampm: false, clearable: true }} />
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Thirdparty"
                        source="third_party"
                        reference="thirdparties"
                        fullWidth>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Internal Apps"
                        source="internal_app"
                        reference="internalapps"
                        fullWidth>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <ImageInput source="image" label="Image" accept="image/*" validate={[required()]}>
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
        <BooleanInput label="Used" source="used" initialValue={false} />
        <BooleanInput label="Visible" source="visible" initialValue={false} />
    </SimpleForm>
);

export default Form;