const CHANGE_OCCUPATIONAL_NOTIFICATION = 'CHANGE_OCCUPATIONAL_NOTIFICATION';

export const changeOccupationalNotification = notification => ({
    type: CHANGE_OCCUPATIONAL_NOTIFICATION,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_OCCUPATIONAL_NOTIFICATION) {
        return payload;
    }
    return previousState;
};
