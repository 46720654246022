import React from 'react';
import { SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput, BooleanInput, DateInput, ImageInput, ImageField } from 'react-admin';
import { findAddress, get_province } from '../../services/address/finder';
import * as R from 'ramda'
import { required } from '../../services/validate'
import { SERVICE_TYPE } from '../../services/service-type';

const provinces = get_province().map(p => ({ value: p.province_code, label: p.province_th }))
const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <ReferenceInput
            label="Main Service"
            source="main_service._id"
            reference="mainservices"
            fullWidth
            validate={[required()]}>
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <ReferenceInput
            label="Provider"
            source={props.isCreate ? "prov_id" : "prov_id._id"}
            reference="providers"
            fullWidth
            validate={[required()]}>
            <SelectInput optionText="company_name" />
        </ReferenceInput>
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <TextInput source="detail_en" label="Detail EN" fullWidth />
        <TextInput source="detail_th" label="Detail TH" fullWidth />
        <TextInput source="detail_ja" label="Detail JA" fullWidth />
        <TextInput source="detail_zh" label="Detail ZH" fullWidth />
        <NumberInput source="cost" label="Cost" fullWidth validate={[required()]} />
        <NumberInput source="qty" label="qty" fullWidth />
        <DateInput label="Start date" source="start_date" options={{ format: 'dd/MM/yyyy' }} />
        <DateInput label="End date" source="end_date" options={{ format: 'dd/MM/yyyy' }} />
        <ImageInput source="images" label="Image" accept="image/*" multiple>
            <ImageField source="fullUrl" title="name" validate={[required()]} />
        </ImageInput>
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;