import React from "react";
import { Edit } from "react-admin";
import Form from "./Form";
import { CustomToolbar } from "../cornrefunds/Edit";

const MgmtHowToBuyEdit = (props) => (
  <Edit {...props}>
    <Form toolbar={<CustomToolbar />} />
  </Edit>
);

export default MgmtHowToBuyEdit;
