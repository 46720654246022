import JQL from "./jql";
export const fieldsEnum = {
  sub_district: "sub_district",
  district: "district",
  province: "province",
  zipcode: "zipcode"
};

export const fieldList = [
  "province",
  "district",
  "sub_district",
];

export const fieldCodeList = [
  "province_code",
  "district_code",
  "amphoe_code",
  "zipcode",
];

// var DB = new JQL(preprocess(require('./geodb.json')));
let DB = null;
let DBType = null;

export const initDb = (dbType = "geodb") => {
  DBType = dbType;
  DB = new JQL(require("../../assets/json/address_data.json"));
  // window.DB = DB;
};

export const resolveResultbyField = (fieldType, searchStr) => {
  let possibles = [];
  try {
    possibles = DB.select("*").where(fieldType).match("^" + searchStr).orderBy(fieldType).fetch();
  } catch (e) {
    return [];
  }
  return possibles;
};

// export const findAddress = (address, fieldType) => {
//     let possibles = [];
//     try {
//         if (typeof address === 'string' && fieldType) {
//             possibles = DB.select('*').where(fieldType).match('^' + address).orderBy(fieldType).fetch();
//         }
//         else if (Object.prototype.toString.call(address) == 'object') {
//             console.warn("object");
//             // possibles = DB.select('*')
//         }
//         else {
//             alert('Wrong address type');
//         }
//     } catch (e) {
//         return [];
//     }
//     return possibles;
// };

// export const validateAddress = (address, fieldType) => {
//     let possibles = [];
//     try {
//         // if (Object.prototype.toString.call(address).slice(8, -1) == 'Object') {
//             possibles = DB.select('*');
//             Object.keys(address).map((key, index) => {
//                 if (fieldList.indexOf(key) > -1) {
//                     possibles.where(key).match('^' + address[key]);
//                 }
//                 return null;
//             });
//             possibles = possibles.fetch();
//         // }
//         // else {
//         //     alert('Address must be an {object}.');
//         //     return { isValid: false, err: 'Address must be an {object}.' };
//         // }
//     } catch (err) {
//         return err;
//         // return { isValid: false, err: err };
//     }
//     return possibles;
//     // if (possibles.length == 0) {
//     //     let err = `Sorry,\nyour address(${JSON.stringify(address)})\nare not in database.\nPlease contact admin or select again.`;
//     //     alert(err);
//     //     return { isValid: false, err: err };
//     // }
//     // return { isValid: true, address: possibles };
// };

export const get_province = (code = null) => {
  if (!DB) {
    initDb();
  }
  let province = DB.select("province_th,province_code,province_en");
  if (code) {
    province.where("province_code").match("^" + code);
  }
  province = province.fetch();
  let province_arr = [];
  // distinct
  for (let key in province) {
    if (province_arr.findIndex(each => each.province_code === province[key].province_code) > -1) {
      continue;
    }
    province_arr[province_arr.length] = province[key];
  }
  return province_arr;
};

export const findAddress = address => {
  if (!DB) {
    initDb();
  }
  let possibles = [];
  try {
    possibles = DB.select("*");
    if ((!!address.amphoe_code || !!address.district_code || !!address.province_code || address.zipcode)) {
      for (let key in address) {
        if (fieldCodeList.indexOf(key) > -1) {
          possibles.where(key).equalTo(parseInt(address[key], 10));
        }
      }
    } else possibles.where('zipcode').match('^bobo');
    // else {
    //   let isKeyValid = Object.keys(address).some((key) => fieldList.indexOf(key) > -1);
    //   if (isKeyValid) {
    //     for (let key in address) {
    //       if (fieldList.indexOf(key) > -1) {
    //         possibles.where(key).match("^" + address[key]);
    //       }
    //     }
    //   }
    //   else {
    //     possibles.where("zipcode").match("^bobo");
    //   }
    // }
    possibles = possibles.fetch();
  } catch (err) {
    return err;
  }
  return possibles;
};