import React from "react";
import * as R from "ramda";
import {
  SimpleForm,
  NumberInput,
  ReferenceInput,
  FormDataConsumer,
  SelectInput,
} from "react-admin";
import { required } from "../../services/validate";

const Form = ({ ...props }) => (
  <SimpleForm {...props}>
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        return (
          <React.Fragment>
            <ReferenceInput
              label="Provider"
              source={props.isCreate ? "provider" : "provider.id"}
              reference="providers"
              fullWidth
              validate={[required()]}
            >
              <SelectInput optionText="company_name" />
            </ReferenceInput>
          </React.Fragment>
        );
      }}
    </FormDataConsumer>
    {/* <NumberInput
      source="max_order"
      label="Max order"
      fullWidth
      validate={[required()]}
    /> */}
    <NumberInput
      source="max_item_per_order"
      label="Max item per order"
      fullWidth
      validate={[required()]}
    />
    <NumberInput
      source="max_qty_per_item"
      label="Max qty per item"
      fullWidth
      validate={[required()]}
    />
  </SimpleForm>
);

export default Form;
