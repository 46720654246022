import list from "./List";
import create from "./Create";
import edit from "./Edit";
import Icon from "@material-ui/icons/Schedule";
export default {
  list,
  // create,
  edit,
  icon: Icon,
};
