import React from 'react';
import { SimpleForm, TextInput, BooleanInput, SelectInput, ReferenceInput, AutocompleteInput, FormDataConsumer, ImageInput, ImageField } from 'react-admin';
import { required } from '../../services/validate'
import bannertype, { TYPE_OBJ } from './bannertype'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_my" label="Name MY" fullWidth validate={[required()]} />
        <SelectInput source="banner_type" choices={bannertype} validate={[required()]}/>
        <TextInput source="url" label="URL" fullWidth validate={[required()]} />
        {/* <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <>
                        {
                            formData.banner_type === TYPE_OBJ.SUB_SERVICE &&
                            <ReferenceInput 
                                label="Sub service"
                                source="sub_service._id"
                                reference="subservices"
                                fullWidth 
                                validate={[required()]}
                                filterToQuery={searchText => ({ name_th_contains: searchText })}>
                                <AutocompleteInput optionText={(choice) => `${choice.name_th} - ${choice.detail_th}`} />
                            </ReferenceInput>
                        }
                        {
                            formData.banner_type === TYPE_OBJ.TOPUP_PACKAGE &&
                            <ReferenceInput 
                                label="Topup Package"
                                source="topup_package._id"
                                reference="topuppackages"
                                fullWidth 
                                validate={[required()]}>
                                <SelectInput optionText="name_th" />
                            </ReferenceInput>
                        }
                        {
                            formData.banner_type === TYPE_OBJ.PROMO_SERVICE &&
                            <ReferenceInput 
                                label="Promotion Service"
                                source="promo_service._id"
                                reference="promoservices"
                                fullWidth 
                                validate={[required()]}>
                                <SelectInput optionText={(choice) => `${choice.name_th} - ${choice.price}`} />
                            </ReferenceInput>
                        }
                        {
                            formData.banner_type === TYPE_OBJ.PROMO_TOPUP &&
                            <ReferenceInput 
                                label="Promotion Topup"
                                source="promo_topup._id"
                                reference="promotopups"
                                fullWidth 
                                validate={[required()]}>
                                <SelectInput optionText={(choice) => `${choice.name_th}`} />
                            </ReferenceInput>
                        }
                    </>
                )
            }}
        </FormDataConsumer> */}
        <ImageInput source="image" label="Image" accept="image/*" validate={[required()]}>
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
        <BooleanInput label="Visible" source="visible" initialValue={false} />
    </SimpleForm>
);

export default Form;