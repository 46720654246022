import React from 'react';
import { SimpleForm, TextInput, BooleanInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput source="name" label="Name" fullWidth validate={[required()]} />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;