export const SERVICE_TYPE = {
    AIR_CLEANING: '5dda665b201335776bcd112b',
    MAID: '5ddab270201335776bcd1161',
    AIRPORT_TRANSFER: '5ddab5b1201335776bcd1171',
    TOWING_CAR: '5df112eb153f322dc7905135',
    EMERGENCY_REPAIRMAN: '5df117ce34e6ab000df0b736',
    HOME_REGIS_NURSE: '5e6a55c6c1f5461a3dc19af0',
    DISINFECTANT: '5e739f2fcb2862b936a2acc7',
    REGIS_NURSE: '5e78548a4edc59000f6b80fe',
    OCCUPATIONAL: '5e875bd04dfd78dbfbee6e2f',
    PHYSICAL: '5e875c574dfd78dbfbee6e32',
    HEALTH_CARE: '5e8e23ca49acbd000fcb6064',
    LIQUID_FOOD: '5eadd483a6b957ecc112a6cf',
    CHILD_FOOD: '5ebb0aeb2b90bc000fcb38c3',
    ELDER_FOOD: '5ebb0b342b90bc000fcb38e9',
    GENERAL_FOOD: '5ebb0b772b90bc000fcb3915',
    VEGETABLE_FOOD: '5ebb0bca2b90bc000fcb392c',
    FOOD: '5ecc96e80e2e8096d4aa2682',
    VIP_PROTECT: '5ee5a01fdf314a000fa1f8be',
    SECURITY: '5ee5df1adf314a000fa1f8c3',
    COURSE_TRAINING: '5ee5df8fdf314a000fa1f8c6',
    COVID: '5ff4b9edd01bfd15cdeacd20',
    INSTANT: '60151080d04427bd54f69bb2',
}

export const SERVICE_NOTI_MINUTE = {
    AIR_CLEANING: 1440, // 1 วัน
    MAID: 1440, // 1 วัน
    AIRPORT_TRANSFER: 60, // 1 ชม.
    TOWING_CAR: 10, // 10 นาที
    EMERGENCY_REPAIRMAN: 10, // 10 นาที
    HOME_REGIS_NURSE: 1440, // 1 วัน
    DISINFECTANT: 1440, // 1 วัน
    REGIS_NURSE: 1440,
    OCCUPATIONAL: 1440,
    PHYSICAL: 1440,
    HEALTH_CARE: 1440,
    LIQUID_FOOD: 10,
}

export const MAIN_SERVICE = {
    air_cleaning: '5dda665b201335776bcd112b',
    maid: '5ddab270201335776bcd1161',
    airport_transfer: '5ddab5b1201335776bcd1171',
    towing_car: '5df112eb153f322dc7905135',
    emergency_repairman: '5df117ce34e6ab000df0b736',
    food: '5ecc96e80e2e8096d4aa2682',
    product_ordering: '5f10e14dec1f090ec7484dbf',
    software: '5f8d8b9b5b74cb000e76625b',
    activity: '5f9c8d2785b30d000f197134',
    hotel: '5f9c8ec085b30d000f197137',
    education: '5f9c8f8685b30d000f19713a',
}