import React, { Fragment, useState } from 'react';
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import {
    Edit, TabbedForm, FormTab, TextField, ReferenceField, SelectField, DateField, NumberField, BooleanField, ReferenceManyField,
    FormDataConsumer, Datagrid, FunctionField, SingleFieldList, ChipField, ArrayField, TextInput
} from 'react-admin';
import Snackbar from '@material-ui/core/Snackbar';
import orderstatustype from './orderstatustype'
import { findAddress } from '../../services/address/finder';
import { ModalActions } from '../../layout/ModalAction';
import { storage } from '../../services';
import API from '../../configs/API';
import TYPE from './orderstatustype';
import OrderStatus from '../../services/OrderStatus';
import { SERVICE_TYPE } from '../../services/service-type';

const onCancelOrder = (data, history, setOpen, setOpenBar) => {
    const payload = {
        reason: data.reason
    }
    const token = storage.load("token");
    const fecthOption = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    }
    fetch(`${API}/orderservices/cancel/${data._id}`, fecthOption).then((response) => response.json()).then((responseJson) => {
        if (!responseJson.error) {
            setOpen(false)
            history.goBack();
            setOpenBar(true)
        }
    });
}

const Form = ({ ...props }) => {
    const [open, setOpen] = useState(false);
    const [openBar, setOpenBar] = useState(false);
    return (
        <TabbedForm {...props}>
            <FormTab label="Summary">
                <TextField label="Ref" source="ref" />
                <ReferenceField label="Main Service" source="mainservice._id" reference="mainservices">
                    <TextField source="name_th" />
                </ReferenceField>
                <ReferenceField label="Service group" source="servicegroup._id" reference="servicegroups">
                    <TextField source="name_th" />
                </ReferenceField>
                <SelectField source="status" choices={orderstatustype} />
                {
                    props.record.review &&
                    <NumberField label="Review score" source="review.score" />
                }
                {
                    props.record.review &&
                    <TextField label="Review detail" source="review.detail" />
                }
                <DateField label="Booking date" source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                <DateField label="Service date time" source="date_time" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                <FunctionField label="Promo code" render={record => record.promo_code_key ? `${record.promo_code_info ? record.promo_code_info.name_th : ''} (${record.promo_code_key || ''})` : ''} />
                <NumberField source="cost" options={{ style: 'currency', currency: 'THB' }} />
                <NumberField source="discount" options={{ style: 'currency', currency: 'THB' }} />
                <NumberField source="sub_total" options={{ style: 'currency', currency: 'THB' }} />
                <NumberField source="total" options={{ style: 'currency', currency: 'THB' }} />
                <NumberField source="profit" options={{ style: 'percent' }} />
                {/* <BooleanField source="payment_term" /> */}
                {props.record.status === OrderStatus.Confirmed && <Button variant="contained" onClick={() => setOpen(true)}>Cancel Order</Button>}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={openBar}
                    onClose={() => setOpenBar(false)}
                    message="Element updated"
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        // console.log(formData)
                        return (
                            <ModalActions open={open} onClose={() => setOpen(false)}
                                title={'Cancel Order'}
                                content={
                                    <TextInput source="reason" label="Reason" />
                                }
                                onOk={() => onCancelOrder(formData, props.history, setOpen, setOpenBar)}
                            />
                        )
                    }}
                </FormDataConsumer>
            </FormTab>
            <FormTab label="Contact">
                {
                    (props.record.cont_firstname && props.record.cont_tel) &&
                    <TextField label="Name" source="cont_firstname" />
                }
                {
                    (props.record.cont_firstname && props.record.cont_tel) &&
                    <TextField label="Tel" source="cont_tel" />
                }
                {
                    (!props.record.cont_firstname && !props.record.cont_tel) &&
                    <TextField label="Firstname" source="client.firstname" />
                }
                {
                    (!props.record.cont_firstname && !props.record.cont_tel) &&
                    <TextField label="Lastname" source="client.lastname" />
                }
                {
                    (!props.record.cont_firstname && !props.record.cont_tel) &&
                    <TextField label="Tel" source="client.tel" />
                }
            </FormTab>
            {props.record.order_details.length > 0 && <FormTab label="Order detail">
                <ArrayField
                    label="Order detail"
                    source="order_details"
                    fullWidth>
                    <Datagrid>
                        <ReferenceField label="Sub service" source="sub_service" reference="subservices">
                            <TextField source="name_th" />
                        </ReferenceField>
                        {(props.record.mainservice.id === SERVICE_TYPE.EMERGENCY_REPAIRMAN ||
                            props.record.mainservice.id === SERVICE_TYPE.AIRPORT_TRANSFER) &&
                            <ReferenceField label="Car brand" source="car_brand._id" reference="carbrands">
                                <TextField source="name_th" />
                            </ReferenceField>}
                        {(props.record.mainservice.id === SERVICE_TYPE.EMERGENCY_REPAIRMAN ||
                            props.record.mainservice.id === SERVICE_TYPE.AIRPORT_TRANSFER) &&
                            <ReferenceField label="Car model" source="car_model._id" reference="carmodels">
                                <TextField source="name_th" />
                            </ReferenceField>}
                        {(props.record.mainservice.id === SERVICE_TYPE.TOWING_CAR) &&
                            <ReferenceField label="Towin Car brand" source="towing_car_brand._id" reference="towingcarbrands">
                                <TextField source="name_th" />
                            </ReferenceField>}
                        {(props.record.mainservice.id === SERVICE_TYPE.TOWING_CAR) &&
                            <ReferenceField label="Towin Car model" source="towing_car_model._id" reference="towingcarmodels">
                                <TextField source="name_th" />
                            </ReferenceField>}
                        {(props.record.mainservice.id === SERVICE_TYPE.HOME_REGIS_NURSE || props.record.mainservice.id === SERVICE_TYPE.OCCUPATIONAL || props.record.mainservice.id === SERVICE_TYPE.MAID || props.record.mainservice.id === SERVICE_TYPE.REGIS_NURSE) &&
                            <NumberField source="hours" />}
                        {(props.record.mainservice.id !== SERVICE_TYPE.TOWING_CAR && props.record.mainservice.id !== SERVICE_TYPE.EMERGENCY_REPAIRMAN && props.record.mainservice.id !== SERVICE_TYPE.OCCUPATIONAL && props.record.mainservice.id !== SERVICE_TYPE.MAID) &&
                            <TextField source="qty" />
                        }
                        {(props.record.mainservice.id === SERVICE_TYPE.FOOD) &&
                            <TextField source="cost" />
                        }
                        <NumberField source="amount" options={{ style: 'currency', currency: 'THB' }} />
                        {(props.record.mainservice.id === SERVICE_TYPE.AIRPORT_TRANSFER) &&
                            <ReferenceManyField label="Order cars" target="order_detail" reference="ordercars">
                                <SingleFieldList>
                                    <ReferenceField label="Author" source="car_id._id" reference="cars">
                                        <ChipField source="license_plate" />
                                    </ReferenceField>
                                </SingleFieldList>
                            </ReferenceManyField>}
                        {/* <ReferenceField label="Promo service" source="promo_service._id" reference="promoservices">
                            <TextField source="name_th" />
                        </ReferenceField> */}
                    </Datagrid>
                </ArrayField>
                <ArrayField
                    label="Order additionals"
                    source="order_additionals"
                    fullWidth>
                    <Datagrid>
                        <ReferenceField label="Additional service" source="additional_service" reference="additionalservices">
                            <TextField source="name_th" />
                        </ReferenceField>
                        <TextField source="detail_th" />
                        <NumberField source="qty" />
                        <NumberField source="amount" options={{ style: 'currency', currency: 'THB' }} />
                    </Datagrid>
                </ArrayField>
            </FormTab>}
            {props.record.order_promos.length > 0 &&
                <FormTab label="Order Promo">
                    <ArrayField
                        label="Order Promo"
                        source="order_promos"
                        fullWidth>
                        <Datagrid>
                            <ReferenceField label="Sub service" source="sub_service_promo" reference="subservicepromos">
                                <TextField source="name_th" />
                            </ReferenceField>
                            {(props.record.mainservice.id === SERVICE_TYPE.EMERGENCY_REPAIRMAN ||
                                props.record.mainservice.id === SERVICE_TYPE.TOWING_CAR ||
                                props.record.mainservice.id === SERVICE_TYPE.AIRPORT_TRANSFER) &&
                                <ReferenceField label="Car brand" source="car_brand._id" reference="carbrands">
                                    <TextField source="name_th" />
                                </ReferenceField>}
                            {(props.record.mainservice.id === SERVICE_TYPE.EMERGENCY_REPAIRMAN ||
                                props.record.mainservice.id === SERVICE_TYPE.TOWING_CAR ||
                                props.record.mainservice.id === SERVICE_TYPE.AIRPORT_TRANSFER) &&
                                <ReferenceField label="Car model" source="car_model._id" reference="carmodels">
                                    <TextField source="name_th" />
                                </ReferenceField>}

                            {(props.record.mainservice.id === SERVICE_TYPE.EMERGENCY_REPAIRMAN ||
                                props.record.mainservice.id === SERVICE_TYPE.TOWING_CAR ||
                                props.record.mainservice.id === SERVICE_TYPE.AIRPORT_TRANSFER) &&
                                <NumberField source="hours" />}
                            <NumberField source="qty" />
                            {(props.record.mainservice.id === SERVICE_TYPE.FOOD) &&
                                <TextField source="cost" />
                            }
                            {(props.record.mainservice.id === SERVICE_TYPE.FOOD) &&
                                <TextField source="price" />
                            }
                            {(props.record.mainservice.id === SERVICE_TYPE.EMERGENCY_REPAIRMAN ||
                                props.record.mainservice.id === SERVICE_TYPE.TOWING_CAR ||
                                props.record.mainservice.id === SERVICE_TYPE.AIRPORT_TRANSFER) &&
                                <ReferenceManyField label="Order cars" target="order_detail" reference="ordercars">
                                    <SingleFieldList>
                                        <ReferenceField label="Author" source="car_id._id" reference="cars">
                                            <ChipField source="license_plate" />
                                        </ReferenceField>
                                    </SingleFieldList>
                                </ReferenceManyField>}
                            <ReferenceField label="Promo service" source="promo_service._id" reference="promoservices">
                                <TextField source="name_th" />
                            </ReferenceField>
                        </Datagrid>
                    </ArrayField>
                    <ArrayField
                        label="Order additionals"
                        source="order_additionals"
                        fullWidth>
                        <Datagrid>
                            <ReferenceField label="Additional service" source="additional_service" reference="additionalservices">
                                <TextField source="name_th" />
                            </ReferenceField>
                            <TextField source="detail_th" />
                            <NumberField source="qty" />
                            <NumberField source="amount" options={{ style: 'currency', currency: 'THB' }} />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
            }
            {props.record.order_rider &&
                <FormTab label="Order-Rider">
                    <ReferenceField label="Ref" source="order_rider.id" reference="orderriders">
                        <TextField source="ref" />
                    </ReferenceField>
                    <NumberField label="Km" source="order_rider.km" />
                    <NumberField label="Price" source="order_rider.price" options={{ style: 'currency', currency: 'THB' }} />
                    <TextField source="status" />
                </FormTab>
            }
            {props.record.order_refund && <FormTab label="Order refund">
                <DateField label="Date" source="order_refund.date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            </FormTab>}
            <FormTab label="Responsibles">
                <ArrayField
                    label="Responsibles"
                    source="responsibles"
                    fullWidth
                >
                    <Datagrid>
                        <ReferenceField label="Provider" source="prov_id" reference="providers">
                            <TextField source="company_name" />
                        </ReferenceField>
                        <TextField source="firstname" />
                        <TextField source="lastname" />
                        <TextField source="tel" />
                    </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="Location">
                {
                    (props.record.residence_type) &&
                    <TextField label="Residence type" source="residence_type.name_th" />
                }
                {
                    (props.record.location && props.record.location.name) &&
                    <TextField label="Name" source="location.name" />
                }
                {
                    (props.record.location && props.record.location.address) &&
                    <TextField label="Address" source="location.address" />

                }
                {
                    (props.record.location && props.record.location.lat && props.record.location.long) &&
                    <FunctionField label="Lat/Lng" render={record => <a target="_blank" href={`https://www.google.co.th/maps?q=${record.location.lat},${record.location.long}`}>{`${record.location.lat} ${record.location.long}`}</a>} />
                }
                {
                    (props.record.location && props.record.location.province) &&
                    <FunctionField label="Province" render={record => {
                        const address = findAddress({ province_code: record.location.province });
                        return `${address[0] ? address[0].province_th : record.location.province}`
                    }}
                    />
                }
                {
                    (props.record.location && props.record.location.district) &&
                    <FunctionField label="District" render={record => {
                        const address = findAddress({ amphoe_code: record.location.district });
                        return `${address[0] ? address[0].amphoe_th : record.location.district}`
                    }}
                    />
                }
                {
                    (props.record.location && props.record.location.sub_district) &&
                    <FunctionField label="Sub dstrict" render={record => {
                        const address = findAddress({ district_code: record.location.sub_district });
                        return `${address[0] ? address[0].district_th : record.location.sub_district}`
                    }}
                    />
                }
                {
                    (props.record.location && props.record.location.zipcode) &&
                    <TextField label="Zipcode" source="location.zipcode" />

                }
                {
                    (props.record.pickup_drops && props.record.pickup_drops.length > 0) &&
                    <ArrayField source="pickup_drops" label="Location" fullWidth>
                        <Datagrid>
                            <TextField label="From Name" source="from_name" />
                            <TextField label="To Name" source="to_name" />
                            <FunctionField label="From Lat/Lng" render={record => <a target="_blank" href={`https://www.google.co.th/maps?q=${record.from_latlong[0]},${record.from_latlong[1]}`}>{`${record.from_latlong[0]} ${record.from_latlong[1]}`}</a>} />
                            <FunctionField label="To Lat/Lng" render={record => <a target="_blank" href={`https://www.google.co.th/maps?q=${record.to_latlong[0]},${record.to_latlong[1]}`}>{`${record.to_latlong[0]} ${record.to_latlong[1]}`}</a>} />
                        </Datagrid>
                    </ArrayField>
                }
            </FormTab>
            <FormTab label="Status">
                <ArrayField label="Status" source="status_times" fullWidth>
                    <Datagrid>
                        <SelectField source="status" choices={orderstatustype} />
                        <DateField label="Date/Time" source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                    </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="Noti">
                <ArrayField label="Noti" source="notis" fullWidth>
                    <Datagrid>
                        <TextField source="noti_type" />
                        <BooleanField source="was_read" />
                        <BooleanField source="delete" />
                    </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="Cancel">
                {
                    props.record.cancel_reason &&
                    <TextField label="Cancel Reason" source="cancel_reason.name_th" />
                }
                {
                    props.record.cancel_other_detail &&
                    <TextField label="Cancel Reason detail" source="cancel_other_detail" />
                }
            </FormTab>
        </TabbedForm>
    )
};

export default withRouter(Form);