import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField } from 'react-admin';

const ServiceCovidInstantList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Sub service" source="sub_service._id" reference="subservices">
                <TextField source="name_th" />
            </ReferenceField>
            <TextField label="Min" source="min" />
            <TextField label="price" source="price" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ServiceCovidInstantList