import React from 'react';
import { SimpleForm, TextInput, BooleanInput, NumberInput, SelectInput, FormDataConsumer, ReferenceInput } from 'react-admin';
import { required } from '../../services/validate'
import { DateTimeInput } from 'react-admin-date-inputs';

const choices = [
    { name: 'value', },
    { name: 'percent', },
];

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Provider"
                            // source="prov_id._id"
                            source={props.isCreate ? "provider" : "provider._id"}
                            reference="providers"
                            fullWidth>
                            <SelectInput optionText="company_name" resettable/>
                        </ReferenceInput>
                        <ReferenceInput
                            label="Main Service"
                            source={formData.mainservice && formData.mainservice._id ? "mainservice._id" : "mainservice._id"}
                            reference="mainservices"
                            fullWidth>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <TextInput source="key" label="Key" fullWidth validate={[required()]} />
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <SelectInput source="promo_code_type" choices={choices} label="Promo code type" optionText="name" optionValue="name" fullWidth validate={[required()]} />
        <NumberInput source="discount" label="Discount" fullWidth validate={[required()]} />
        <NumberInput source="qty" label="Qty" fullWidth validate={[required()]} />
        <NumberInput source="min_purchase" label="Min Purchase" fullWidth validate={[required()]} />
        <DateTimeInput source="start_date" label="Start date" fullWidth validate={[required()]} options={{ format: 'dd/MM/yyy, HH:mm:ss', ampm: false, clearable: true }} />
        <DateTimeInput source="end_date" label="End date" fullWidth validate={[required()]} options={{ format: 'dd/MM/yyy, HH:mm:ss', ampm: false, clearable: true }} />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;