const CHANGE_PHYSICAL_NOTIFICATION = 'CHANGE_PHYSICAL_NOTIFICATION';

export const changePhysicalNotification = notification => ({
    type: CHANGE_PHYSICAL_NOTIFICATION,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_PHYSICAL_NOTIFICATION) {
        return payload;
    }
    return previousState;
};
