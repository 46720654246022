import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

const MainServiceList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="legal" source="legal_th" />
            <EditButton />
        </Datagrid>
    </List>
);

export default MainServiceList