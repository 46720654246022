import React from 'react';
import {
    TabbedForm, FormTab, TextInput, BooleanInput, SelectInput, FormDataConsumer, PasswordInput, ImageInput, ImageField, Datagrid, TextField,
    ReferenceManyField, SelectField, ReferenceField, DateField, NumberField, Pagination, ArrayField, ReferenceInput,
    FunctionField, List, pagination
} from 'react-admin';
import { required } from '../../services/validate'
import { invertObj } from 'ramda';
import { findAddress } from '../../services/address/finder';

const Form = ({ ...props }) => {
    return (
        <TabbedForm {...props}>
            <FormTab label="Information">
                <TextField source="company_name" />
                <TextField source="address" />
                {props.record.district && <FunctionField label="District" render={record => {
                    const address = findAddress({ amphoe_code: record.district });
                    return `${address[0] ? address[0].amphoe_th : record.district}`
                }}
                />}
                {props.record.province && <FunctionField label="Province" render={record => {
                    const address = findAddress({ province_code: record.province });
                    return `${address[0] ? address[0].province_th : record.province}`
                }}
                />}
                {props.record.province && <TextField source="zipcode" />}
                <TextInput source="tel" />
                <TextInput source="line_id" />
                <TextInput source="provider_line" />
                <BooleanInput source="proved" />
            </FormTab>
            {/* <FormTab label="Employee">
                <ReferenceManyField
                    label="Employee"
                    reference="employees"
                    filter={{
                        prov_id: props.record.id,
                    }}
                    pagination={<Pagination />}
                    target=''
                    sort={{ field: 'id', order: 'ASC' }}
                    fullWidth>
                    <Datagrid>
                        <ReferenceField label="Firstname" source="_id" reference="employees">
                            <TextField source="firstname" />
                        </ReferenceField>
                        <TextField source="lastname" />
                        <TextField source="tel" label="Tel" />
                        <TextField source="email" />
                        <TextField source="line_id" />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab> */}
            <FormTab label="User">
                <ReferenceManyField
                    label="User"
                    reference="users"
                    filter={{
                        provider_id: props.record.id,
                    }}
                    pagination={<Pagination />}
                    target=''
                    // sort={{ field: 'id', order: 'ASC' }}
                    fullWidth>
                    <Datagrid>
                        {/* <ReferenceField label="Username" source="_id" reference="users"> */}
                            <TextField source="username" />
                        {/* </ReferenceField> */}
                        <TextField source="email" />
                        <TextField source="regis_type" />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            {/* <FormTab label="Car">
                <ReferenceManyField
                    label="Car"
                    reference="cars"
                    filter={{
                        prov_id: props.record.id,
                    }}
                    pagination={<Pagination />}
                    target=''
                    sort={{ field: 'createdAt', order: 'ASC' }}
                    fullWidth>
                    <Datagrid>
                        <ReferenceField label="License plate" source="_id" reference="cars">
                            <TextField source="license_plate" />
                        </ReferenceField>
                        <FunctionField label="Province" render={record => {
                            const address = findAddress({ province_code: record.province });
                            return `${address[0] ? address[0].province_th : record.province}`
                        }}
                        />
                        <TextField source="color" label="color" />
                        <TextField source="seat" label="seat" />
                        <ReferenceField label="Car Brand" source="car_brand._id" reference="carbrands">
                            <TextField source="name_th" />
                        </ReferenceField>
                        <ReferenceField label="Car Brand" source="car_model._id" reference="carmodels">
                            <TextField source="name_th" />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab> */}
            <FormTab label="Service">
                <ReferenceManyField
                    label="Service"
                    reference="mgmtservices"
                    filter={{
                        prov_id: props.record.id,
                    }}
                    pagination={<Pagination />}
                    target=''
                    sort={{ field: 'createdAt', order: 'ASC' }}
                    fullWidth>
                    <Datagrid>
                        <ReferenceField label="Main service" source="servicegroup._id" reference="servicegroups">
                            <TextField source="name_th" />
                        </ReferenceField>
                        <ReferenceField label="Main service" source="mainservice._id" reference="mainservices">
                            <TextField source="name_th" />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    )
};

export default Form;