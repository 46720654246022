import React from 'react';
import * as R from 'ramda'
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceInput, SelectInput, AutocompleteInput, ImageInput, ImageField, NumberInput } from 'react-admin';
import { required } from '../../services/validate'

const choices = [
    { name: 'sub_service' },
    { name: 'topup_package' },
    { name: 'promo_service' },
    { name: 'promo_topup' },
    { name: 'url' },
];

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Main Service"
                        source={formData.mainservice && formData.mainservice._id ? "mainservice._id" : "mainservice._id"}
                        reference="mainservices"
                        fullWidth
                        validate={[required()]}>
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <TextInput source="name_my" label="Name MY" fullWidth validate={[required()]} />
        <AutocompleteInput source="banner_type" choices={choices} optionText="name" optionValue="name" fullWidth />
        <TextInput source="url" label="Url" fullWidth  />
        <ImageInput source="image" label="Image" accept="image/*" validate={[required()]} >
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
        <BooleanInput label="visible" source="visible" />
    </SimpleForm>
);

export default Form;