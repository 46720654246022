import React from 'react';
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Provider"
                            // source="prov_id._id"
                            source={props.isCreate ? "provider" : "provider._id"}
                            reference="providers"
                            fullWidth>
                            <SelectInput optionText="company_name" />
                        </ReferenceInput>
                        <ReferenceInput
                            label="Main Service"
                            source={formData.main_service && formData.mainservice._id ? "mainservice._id" : "mainservice._id"}
                            reference="mainservices"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                        <ReferenceInput
                            label="Shipping type"
                            source={formData.shipping_type && formData.shipping_type._id ? "shipping_type._id" : "shipping_type._id"}
                            reference="shippingtypes"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        {/* <BooleanInput label="Visible" source="visible" /> */}
    </SimpleForm>
);

export default Form;