import React from 'react';
import {
    CardActions,
    CreateButton,
    RefreshButton,
} from 'react-admin';
import ReactFileReader from "react-file-reader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Papa from 'papaparse';
import Icon from "@material-ui/icons/CloudUpload";
import { uploadExcel } from '../services/uploadExcel';

export class ImportActions extends React.Component {
    state = {
        loading: false,
        open: false,
        files: null,
    };

    handleOpen = () => this.setState({ open: true });
    handleClose = () =>
        this.setState({ open: false, project: "", files: null });
    setLoading = (loading = true) => this.setState({ loading, open: false });

    transformFile = () => {
        const { files } = this.state;
        const suffix = files[0].name.slice(files[0].name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        files.url = filename;
        this.onUploadExcel(files);
    };

    onUploadExcel = file => {
        const { resource } = this.props;
        const reader = new FileReader();
        reader.readAsText(file[0]);
        reader.onload = () => {
            Papa.parse(reader.result, {
                complete: data => {
                    uploadExcel(data.data.filter((each, i) => i > 0), resource, this.setLoading)
                },
            });
        };
    }

    render() {
        const { loading, files } = this.state;
        const {
            resource,
            filters,
            displayedFilters,
            filterValues,
            basePath,
            showFilter,
            title,
            example,
        } = this.props;

        return (
            <CardActions style={{ paddingTop: 20 }}>
                {filters &&
                    React.cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: "button"
                    })}
                <CreateButton basePath={basePath} />
                <RefreshButton />
                <Button
                    onClick={this.handleOpen}
                    primary="true"
                    style={{ color: "#3f51b5", padding: '2px 8px' }}
                >
                    <Icon style={{ marginRight: 10 }} />
                    {'Import'}
                </Button>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: "4px",
                            width: "70%",
                            margin: "0 auto",
                            minHeight: "300px",
                            top: "20%"
                        }}
                    >
                        <Typography variant="title" id="modal-title">
                            {title}
                        </Typography>
                        <Typography variant="subheading" id="simple-modal-description" />
                        <div style={{ marginTop: 20 }}>
                            <a href={example}>Download Example</a>
                        </div>
                        <div style={{ marginTop: 30 }}>
                            <ReactFileReader
                                fileTypes={[".csv"]}
                                handleFiles={files => this.setState({ files })}
                            >
                                <Button variant="raised" component="span">
                                    Choose file
                  </Button>
                                {files &&
                                    files[0] &&
                                    files[0].name && (
                                        <span style={{ marginLeft: 10 }}>{files[0].name}</span>
                                    )}
                            </ReactFileReader>
                        </div>
                        <div style={{ position: "absolute", bottom: 20, right: 30 }}>
                            <Button
                                primary="true"
                                style={{ color: "red" }}
                                onClick={this.handleClose}
                            >
                                Cancel
                  </Button>
                            <Button
                                primary="true"
                                style={{ color: "#3f51b5" }}
                                onClick={this.transformFile}
                            >
                                UPLOAD
                  </Button>
                        </div>
                    </div>
                </Modal>
                <Loading open={loading} />
            </CardActions>
        );
    }
}


const Loading = ({ ...other }) => (
    <Dialog {...other}>
        <DialogTitle id="simple-dialog-title">Loading...</DialogTitle>
        <div style={{ textAlign: "center", marginBottom: 20, height: 50 }}>
            <CircularProgress />
        </div>
    </Dialog>
);