import React from 'react';
import { SimpleForm, TextInput, ReferenceInput, SelectInput, ArrayInput, SimpleFormIterator, BooleanInput, AutocompleteInput, AutocompleteArrayInput, FormDataConsumer, ImageInput, ImageField } from 'react-admin';
import { findAddress, get_province } from '../../services/address/finder';
import * as R from 'ramda'
import { required } from '../../services/validate'
import { SERVICE_TYPE } from '../../services/service-type';

const provinces = get_province().map(p => ({ value: p.province_code, label: p.province_th }))
const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <ReferenceInput
            label="Main Service"
            source="main_service._id"
            reference="mainservices"
            fullWidth
            // perPage={100}
            filter={{ visible: true }}
            validate={[required()]}>
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <TextInput source="name_my" label="Name MY" fullWidth validate={[required()]} />
        <TextInput source="detail_en" label="Detail EN" fullWidth />
        <TextInput source="detail_th" label="Detail TH" fullWidth />
        <TextInput source="detail_ja" label="Detail JA" fullWidth />
        <TextInput source="detail_zh" label="Detail ZH" fullWidth />
        <TextInput source="detail_my" label="Detail MY" fullWidth />
        {!props.isCreate && props.record.main_service && (props.record.main_service._id === SERVICE_TYPE.AIRPORT_TRANSFER ||
            props.record.main_service._id === SERVICE_TYPE.TOWING_CAR || props.record.main_service._id === SERVICE_TYPE.EMERGENCY_REPAIRMAN)
            && <FormDataConsumer>
                {({ formData, className, ...rest }) => {
                    return <ArrayInput source="car_areas" label="Car Area" {...rest} fullWidth>
                        <SimpleFormIterator>
                            <CarArea formData={formData} />
                        </SimpleFormIterator>
                    </ArrayInput>
                }}
            </FormDataConsumer>}
        <ImageInput source="images" label="Image" accept="image/*" multiple>
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

const CarArea = props => {
    const { formData, source } = props;
    const index = source.match(/\d/g).join("");
    const districts = !!formData.car_areas[index] && formData.car_areas[index].province ? findAddress({ province_code: formData.car_areas[index].province }) : []
    const districtOptions = !!formData.car_areas[index] && formData.car_areas[index].province
        ? R.unionWith(R.eqBy(R.prop('amphoe_code')), districts, []).map(address => ({
            value: address.amphoe_code,
            label: address.amphoe_th,
        }))
        : [];
    return (
        <React.Fragment>
            <AutocompleteInput source={`${source}.province`} label="Province" choices={provinces} optionText="label" optionValue="value" fullWidth />
            <AutocompleteArrayInput source={`${source}.district`} label="District"
                choices={districtOptions} optionText="label" optionValue="value" fullWidth
            />
            <ReferenceInput source={`${source}.place`} reference="places" label="Place" fullWidth>
                <SelectInput optionText="name_th" />
            </ReferenceInput>
        </React.Fragment>
    );
};

export default Form;