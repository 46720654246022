import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

const TermInterestRateList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Term" source="term" />
            <TextField label="Interest Rate" source="interest_rate" />
            <EditButton />
        </Datagrid>
    </List>
);

export default TermInterestRateList