import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, DateField, ReferenceInput, SelectInput } from 'react-admin';
import { ImportActions } from '../../layout/ImportActions';
import promocode from './promocode.xlsx';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Key" source="key_contains" />
        <TextInput label="Name" source="name_th_contains" />
        <ReferenceInput label="Provider" source="prov_id._id" reference="providers">
            <SelectInput optionText="company_name" />
        </ReferenceInput>
    </Filter>
)

const PromoCodeList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}
        actions={<ImportActions title={'Import Promo-code by CSV'} resource="promocodes" example={promocode} />}>
        <Datagrid rowClick="edit">
            <TextField label="Key" source="key" />
            <TextField label="Name" source="name_th" />
            <TextField label="Discount" source="discount" />
            <TextField label="Qty" source="qty" />
            <DateField label="Start Date" source="start_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <DateField label="End Date" source="end_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <EditButton />
        </Datagrid>
    </List>
);

export default PromoCodeList