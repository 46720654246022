import React from 'react';
import { SimpleForm, ReferenceInput, SelectInput, AutocompleteInput, BooleanInput, NumberInput } from 'react-admin';
import { required } from '../../services/validate'
import { SERVICE_TYPE } from '../../services/service-type';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <ReferenceInput label="Car Brand" source="car_brand._id" reference="carbrands" validate={[required()]}>
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <ReferenceInput label="Car Model" source="car_model._id" reference="carmodels" validate={[required()]}>
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <ReferenceInput label="Sub services" source="sub_service._id" reference="subservices" filterToQuery={searchText => ({ name_th_contains: searchText })}
            filter={{ main_service: { _id: SERVICE_TYPE.AIRPORT_TRANSFER } }}
            validate={[required()]}>
            <AutocompleteInput optionText="name_th" />
        </ReferenceInput>
        <NumberInput source="price" validate={[required()]} />
        <SelectInput source="gear_type" validate={[required()]} choices={[
            { id: 'AT', name: 'AT' },
            { id: 'MT', name: 'MT' },
        ]} />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;