import React from 'react';
import * as R from 'ramda'
import { SimpleForm, TextInput, FormDataConsumer, ReferenceInput, SelectInput, ImageInput, ImageField, AutocompleteInput } from 'react-admin';
import { required } from '../../services/validate'
import { get_province } from '../../services/address/finder';

const provinces = get_province().map(p => ({ value: `${p.province_code}`, label: p.province_th }))

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput source="license_plate" label="License plate" fullWidth validate={[required()]} />
        <AutocompleteInput source="province" label="Province" choices={provinces} optionText="label" optionValue="value" fullWidth />
        <TextInput source="color" label="Color" fullWidth validate={[required()]} />
        <TextInput source="seat" label="Seat" fullWidth validate={[required()]} />
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Car brand"
                        source={formData.car_brand && formData.car_brand._id ? "car_brand._id" : "car_brand._id"}
                        reference="carbrands"
                        fullWidth>
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Car model"
                        source={formData.car_model && formData.car_model._id ? "car_model._id" : "car_model._id"}
                        reference="carmodels"
                        fullWidth
                        filter={{ car_brand: { _id: formData.car_brand && formData.car_brand._id ? formData.car_brand._id : '' } }}
                    >
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Provider"
                        source={"prov_id._id"}
                        reference="providers"
                        fullWidth
                        validate={[required()]}>
                        <SelectInput optionText="company_name" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <ImageInput source="attachs" label="Attachs" accept="image/*">
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
    </SimpleForm>
);

export default Form;