const TYPE = [
    { id: 0, name: 'Pending' },
    { id: 1, name: 'Paid' },
    { id: 2, name: 'Confirm' },
    { id: 3, name: 'CheckIn' },
    { id: 4, name: 'Completed' },
    { id: 5, name: 'Cancelled' },
    { id: 9, name: 'Drop' },
];

export default TYPE;