import React from 'react';
import { Create } from 'react-admin';
import Form from './Form'

const CreateForm = (props) => (
    <Create {...props}>
        <Form/>
    </Create>
);

export default CreateForm