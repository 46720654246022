const CHANGE_MAID_NOTIFICATION = 'CHANGE_MAID_NOTIFICATION';

export const changeMaidNotification = notification => ({
    type: CHANGE_MAID_NOTIFICATION,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_MAID_NOTIFICATION) {
        return payload;
    }
    return previousState;
};
