import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, BooleanField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name_th_contains" />
    </Filter>
)

const AntiSeptictypeList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Name" source="name_th" />
            <BooleanField label="Visible" source="visible" />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default AntiSeptictypeList