import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, ReferenceField, ReferenceInput, SelectInput, EditButton } from 'react-admin';
import { union } from 'ramda'
import { findAddress } from '../../services/address/finder';

const ListFilter = (props) => (
    <Filter {...props}>
        {/* <TextInput label="Company name" source="prov_id.company_name_contains" /> */}
        {/* <TextInput label="Name" source="name_th_contains" /> */}
        <ReferenceInput source="main_service" reference="mainservices" filter={{ visible: true }}>
            <SelectInput optionText="name_th" />
        </ReferenceInput>
    </Filter>
)

const SubServiceList = props => (
    <List {...props} filters={<ListFilter />}>
        <Datagrid rowClick="edit">
            <TextField label="Name" source="name_th" />
            <ReferenceField label="Main Service" source="main_service._id" reference="mainservices">
                <TextField source="name_th" />
            </ReferenceField>
            {/* <CarArea label="Car Area" /> */}
            <EditButton />
        </Datagrid>
    </List>
);

const CarArea = ({ record }) => {
    const carAreaProvince = union(record.car_areas.map(area => area.province), [])
        .map(province => {
            const address = findAddress({ province_code: province });
            return `${address[0] ? address[0].province_th : province}`
        }
        );
    return (
        <>
            {carAreaProvince.map(item => (
                <div className="MuiChip-root" style={{ margin: 4 }} key={item}><span className="MuiChip-label">{item}</span></div>
            ))}
        </>
    )
}

CarArea.defaultProps = { addLabel: true };

export default SubServiceList