import React from 'react';
import { SimpleForm, NumberInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <NumberInput source="term" label="Term" fullWidth validate={[required()]} />
        <NumberInput source="interest_rate" label="Interest Rate" fullWidth validate={[required()]} />
    </SimpleForm>
);

export default Form;