import React from 'react';
import {
    SimpleForm, TextInput, BooleanInput, FormDataConsumer,
    ImageInput, ImageField, ReferenceInput, SelectInput, ArrayInput, SimpleFormIterator,
    NumberInput
} from 'react-admin';
import { required } from '../../services/validate'
import { DateTimeInput } from 'react-admin-date-inputs';

const choices = [
    { id: 'value', name: 'บาท' },
    { id: 'percent', name: '% (เปอร์เซ็นต์)' },
];

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Provider"
                            // source="prov_id._id"
                            source={props.isCreate ? "prov_id" : "prov_id._id"}
                            reference="providers"
                            fullWidth>
                            <SelectInput optionText="company_name" />
                        </ReferenceInput>
                        <SelectInput source="promo_type" choices={choices} label="Promo type" optionText="name" optionValue="id" fullWidth validate={[required()]} />
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <NumberInput source="discount" label="Discount" fullWidth validate={[required()]} />
        <NumberInput source="shipping_price" label="Shipping price" fullWidth />
        <NumberInput source="min_purchase" label="Min purchase" fullWidth validate={[required()]} />
        <NumberInput source="qty" label="Qty" fullWidth validate={[required()]} />
        <DateTimeInput source="start_date" label="Start date" fullWidth options={{ format: 'dd/MM/yyy, HH:mm:ss', ampm: false, clearable: true }} />
        <DateTimeInput source="end_date" label="End date" fullWidth options={{ format: 'dd/MM/yyy, HH:mm:ss', ampm: false, clearable: true }} />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;