import React from 'react';
import * as R from 'ramda'
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceInput, SelectInput, AutocompleteInput, ImageInput, ImageField, NumberInput } from 'react-admin';
import { required } from '../../services/validate'

const choices = [
    { name: 'serviceairclean' },
    { name: 'servicemaid' },
    { name: 'servicecarwdairport' },
    { name: 'servicesostowingcar' },
    { name: 'servicesosrepair' },
    { name: 'serviceregisnurzs' },
    { name: 'servicenurzs' },
    { name: 'serviceviruscleaner' },
    { name: 'serviceoccupationaltherapy' },
    { name: 'servicephysicaltherapy' },
    { name: 'servicehealthcare' },
    { name: 'servicefoodchild' },
    { name: 'servicefoodelderly' },
    { name: 'servicefoodgeneral' },
    { name: 'servicefoodvegetable' },
];

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Service group"
                        source={formData.service_group && formData.service_group._id ? "service_group._id" : "service_group._id"}
                        reference="servicegroups"
                        fullWidth
                        validate={[required()]}>
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <TextInput source="name_my" label="Name MY" fullWidth validate={[required()]} />
        <TextInput source="detail_th" label="detail TH" fullWidth validate={[required()]} />
        <TextInput source="detail_en" label="detail EN" fullWidth validate={[required()]} />
        <TextInput source="detail_ja" label="detail JA" fullWidth validate={[required()]} />
        <TextInput source="detail_zh" label="detail ZH" fullWidth validate={[required()]} />
        <TextInput source="detail_my" label="detail MY" fullWidth validate={[required()]} />
        <NumberInput source="min_purchase" label="Min Purchase" fullWidth />
        <NumberInput source="max_purchase" label="Max Purchase" fullWidth />
        <AutocompleteInput source="sub_service_table" choices={choices} optionText="name" optionValue="name" fullWidth />
        <TextInput source="path" label="Path" fullWidth validate={[required()]} />
        <TextInput multiline source="desc_text_th" label="Desc text TH" fullWidth />
        <TextInput multiline source="desc_text_en" label="Desc text EN" fullWidth />
        <TextInput multiline source="desc_text_ja" label="Desc text JA" fullWidth />
        <TextInput multiline source="desc_text_zh" label="Desc text ZH" fullWidth />
        <TextInput multiline source="desc_text_my" label="Desc text MY" fullWidth />
        <ImageInput source="image" label="Image" accept="image/*" validate={[required()]} >
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
        <BooleanInput label="visible" source="visible" />
    </SimpleForm>
);

export default Form;