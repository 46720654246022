import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput multiline source="payment_term_en" label="Payment term EN" fullWidth validate={[required()]} />
        <TextInput multiline source="payment_term_th" label="Payment term TH" fullWidth validate={[required()]} />
        <TextInput multiline source="payment_term_ja" label="Payment term JA" fullWidth validate={[required()]} />
        <TextInput multiline source="payment_term_zh" label="Payment term ZH" fullWidth validate={[required()]} />
    </SimpleForm>
);

export default Form;