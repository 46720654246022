import React from 'react';
import { SimpleForm, TextInput, BooleanInput, ImageInput, ImageField } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <TextInput source="name_my" label="Name MY" fullWidth validate={[required()]} />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;