const CHANGE_HOME_REGIS_NURSE_NOTIFICATION = 'CHANGE_HOME_REGIS_NURSE_NOTIFICATION';

export const changeHomeRegisNurseNotification = notification => ({
    type: CHANGE_HOME_REGIS_NURSE_NOTIFICATION,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_HOME_REGIS_NURSE_NOTIFICATION) {
        return payload;
    }
    return previousState;
};
