import React from 'react';
import * as R from 'ramda'
import { SimpleForm, TextInput, FormDataConsumer, ReferenceArrayInput, NumberInput, SelectArrayInput } from 'react-admin';
import { required } from '../../services/validate'
import Geosuggest from 'react-geosuggest';
import { compose, withProps } from 'recompose';
import { useForm } from 'react-final-form';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import '../places/GooglePlacesComplete.css'

const choices = [
    { name: 'รถลาก', value: 'Towing' },
    { name: 'ช่างซ่อมฉุกเฉิน', value: 'Repair' },
    { name: 'ทั้งสอง', value: 'Both' },
];
let maps;

const GeosuggestForm = ({ formData, ...rest }) => {
    const form = useForm();
    return (
        <React.Fragment>
            <Geosuggest onSuggestSelect={(obj) => {
                if (!!maps) {
                    if (obj) {
                        form.change('position', obj.location)
                        form.change('lat', obj.location.lat)
                        form.change('long', obj.location.lng)
                        form.change('location.coordinates.1', obj.location.lat)
                        form.change('location.coordinates.0', obj.location.lng)
                        form.change('location.type', 'Point')
                        maps.panTo(obj.location)
                    }
                }
            }} />
        </React.Fragment>
    );
};

const MapForm = ({ formData, ...rest }) => {
    const form = useForm();
    formData.position = !!formData.location && formData.location.coordinates ?
        { lat: formData.location.coordinates[1], lng: formData.location.coordinates[0] } : { lat: formData.lat, lng: formData.long }
    const maker = !!formData.position && formData.position.lat && formData.position.lng ?
        [{ position: { ...formData.position } }] : []
    if (maps && maker.length > 0) maps.panTo({ ...formData.position })
    return (
        <React.Fragment>
            <Map isMarkerShown
                markers={maker}
                setRefMap={(map) => {
                    maps = map;
                    if (maps && maker.length > 0) maps.panTo({ ...formData.position })
                }}
                onMapClick={(event) => {
                    let position = { lat: event.latLng.lat(), lng: event.latLng.lng() }
                    form.change('position', position)
                    form.change('lat', position.lat)
                    form.change('long', position.lng)
                    form.change('location.type', 'Point')
                    form.change('location.coordinates.1', position.lat)
                    form.change('location.coordinates.0', position.lng)
                }} />
        </React.Fragment>
    );
};
const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput source="provider_code" label="Provider Code" fullWidth validate={[required()]} />
        <FormDataConsumer>
            {formDataProps => {
                return <GeosuggestForm {...formDataProps} />
            }}
        </FormDataConsumer>
        <NumberInput label="Lat" source="location.coordinates.1" style={{ marginRight: 20, float: 'left' }} />
        <NumberInput label="Long" source="location.coordinates.0" />
        <FormDataConsumer>
            {formDataProps => {
                return <MapForm {...formDataProps} />
            }}
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceArrayInput
                        label="Garage Service"
                        source="services"
                        reference="garageservices"
                        fullWidth
                    >
                        <SelectArrayInput optionText="name_th" />
                    </ReferenceArrayInput>
                )
            }}
        </FormDataConsumer>
        {/* <SelectArrayInput source="main_services" choices={choices} optionText="name" optionValue="value" /> */}
    </SimpleForm>
);

const Map = compose(
    withProps({
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap
)(({ markers, ...props }) => {
    return (
        <GoogleMap
            ref={props.setRefMap}
            defaultZoom={18}
            defaultCenter={markers.length > 0 ? { ...markers[0].position } : { lat: 13.7563309, lng: 100.50176510000006 }}
            onClick={props.onMapClick}>
            {markers.map((marker, index) => (
                <Marker key={index} {...marker} />
            ))}
        </GoogleMap>
    )
});
export default Form;