import React from "react";
import { Create } from "react-admin";
import Form from "./Form";

const MgmtHowToBuyProviderCreate = (props) => (
  <Create {...props}>
    <Form isCreate />
  </Create>
);

export default MgmtHowToBuyProviderCreate;
