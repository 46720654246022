import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import storage from './storage'
import API from '../configs/API'

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${API}/auth/local`, {
      method: 'POST',
      body: JSON.stringify({ identifier: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);
        return response.json();
      })
      .then(({ jwt, user }) => {
        // const { value: { type_of_role } } = user
        storage.save('token', jwt, 1000000000)
        storage.save('user', user, 1000000000)
        switch (user.type_of_role) {
          case 1:
            storage.save('permission', 'super-admin', 1000000000)
            break;
          case 2:
            storage.save('permission', 'admin', 1000000000)
            break;
          case 3:
            storage.save('permission', 'operator', 1000000000)
            break;
          default:
            storage.save('permission', 'admin', 1000000000)
            break;
        }
      }).catch(() => { throw new Error('Unauthorized'); });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    storage.remove('token');
    storage.remove('user');
    storage.remove('permission');
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      storage.remove('token');
      storage.remove('user');
      storage.remove('permission');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return (storage.load('token') && storage.load('user'))
      ? Promise.resolve()
      : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = storage.load('permission');
    return role ? Promise.resolve(role) : Promise.reject();
  }
  return Promise.reject('Unknown method');
};