import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from "react-router-dom";
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
import { UserMenu } from 'react-admin';
import AirCleaningIcon from "@material-ui/icons/Cloud";
import MaidIcon from "@material-ui/icons/Home";
import AirportTransferIcon from "@material-ui/icons/DirectionsCar";
import TowingCarIcon from "@material-ui/icons/Notifications";
import RepairmanIcon from "@material-ui/icons/Build";
import LocalHospital from "@material-ui/icons/LocalHospital";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Opacity from "@material-ui/icons/Opacity";
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-router-dom";
import API from '../configs/API';
import { SERVICE_TYPE, SERVICE_NOTI_MINUTE } from '../services/service-type';
import { storage } from '../services';
import moment from 'moment';
import { changeAirCleaningNotification } from '../reducers/AirCleaningNotiReducer';
import { changeMaidNotification } from '../reducers/MaidNotiReducer';
import { changeAirportNotification } from '../reducers/AirportNotiReducer';
import { changeTowingCarNotification } from '../reducers/TowingCarNotiReducer';
import { changeRepairNotification } from '../reducers/RepairNotiReducer';
import { changeRegisNurseNotification } from '../reducers/RegisNurseNotiReducer';
import { changeHomeRegisNurseNotification } from '../reducers/HomeRegisNurseNotiReducer';
import { changeVirusNotification } from '../reducers/VirusNotiReducer';
import { changeOccupationalNotification } from '../reducers/OccupationalNotiReducer';
import { changePhysicalNotification } from '../reducers/PhysicalNotiReducer';
import { changeHealthCareNotification } from '../reducers/HealthCareNotiReducer';

const styles = theme =>
    createStyles({
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            zIndex: 1300,
        },
        toolbar: {
            paddingRight: 24,
            minHeight: 55
        },
        menuButton: {
            marginLeft: '0.5em',
            marginRight: '0.5em',
        },
        menuButtonIconClosed: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(0deg)',
        },
        menuButtonIconOpen: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(180deg)',
        },
        title: {
            flex: 1,
            // textOverflow: 'ellipsis',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
        },
        loadingIndicator: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            zIndex: 1200,
            marginBottom: 16,
            marginTop: 16,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        notificationbutton: {
            color: "white"
        },
        logout: {
            color: 'white !important',
        },
    });

class AppBar extends React.Component {
    state = {}

    componentDidMount() {
    }

    render() {
        const { children, classes, className, logo, logout, open,
            title, toggleSidebar, userMenu, width, AirCleningNoti,
            MaidNoti, AirportNoti, TowingNoti, RepairNoti, RegisNoti, VirusNoti, HomeRegisNoti,
            OccupationalNoti, PhysicalNoti, HealthCareNoti, ...rest } = this.props
        const toDay = new Date()
        return (
            <MuiAppBar
                className={className}
                color="secondary"
                position="absolute"
                {...rest}
            >
                <Toolbar
                    disableGutters
                    // variant={width === 'xs' ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleSidebar}
                        className={classNames(classes.menuButton)}
                    >
                        <MenuIcon
                            classes={{
                                root: open
                                    ? classes.menuButtonIconOpen
                                    : classes.menuButtonIconClosed,
                            }}
                        />
                    </IconButton>
                    {Children.count(children) === 0 ? (
                        <Typography
                            variant="title"
                            color="inherit"
                            className={classes.title}
                            id="react-admin-title"
                        />
                    ) : (
                        children
                    )}
                    {logout && cloneElement(userMenu, { logout })}
                </Toolbar>
            </MuiAppBar>
        );
    }
}

AppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    logout: PropTypes.element,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    userMenu: PropTypes.node,
    width: PropTypes.string,
};

AppBar.defaultProps = {
    userMenu: <UserMenu />,
};
const mapStateToProps = state => ({
    AirCleningNoti: state.AirCleningNoti,
    MaidNoti: state.MaidNoti,
    AirportNoti: state.AirportNoti,
    TowingNoti: state.TowingNoti,
    RepairNoti: state.RepairNoti,
    HomeRegisNoti: state.HomeRegisNoti,
    RegisNoti: state.RegisNoti,
    VirusNoti: state.VirusNoti,
    OccupationalNoti: state.OccupationalNoti,
    PhysicalNoti: state.PhysicalNoti,
    HealthCareNoti: state.HealthCareNoti,
});

const enhance = compose(
    connect(
        mapStateToProps,
        {
            changeAirCleaningNotification,
            changeMaidNotification,
            changeAirportNotification,
            changeTowingCarNotification,
            changeRepairNotification,
            changeHomeRegisNurseNotification,
            changeRegisNurseNotification,
            changeVirusNotification,
            changeOccupationalNotification,
            changePhysicalNotification,
            changeHealthCareNotification,
            toggleSidebar: toggleSidebarAction,
        },
    ),
    withStyles(styles),
    withWidth()
);

export default enhance(AppBar);