import React from 'react';
import * as R from 'ramda'
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceArrayInput, SelectArrayInput, AutocompleteInput, ImageInput, ImageField } from 'react-admin';
import { required } from '../../services/validate'

const choices = [
    { name: 'serviceairclean' },
    { name: 'servicemaid' },
    { name: 'servicecarwdairport' },
    { name: 'servicesostowingcar' },
    { name: 'servicesosrepair' },
];

const Form = ({ ...props }) => (
    <SimpleForm {...props}>

        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <TextInput source="name_my" label="Name MY" fullWidth validate={[required()]} />
        <TextInput source="detail_th" label="detail TH" fullWidth validate={[required()]} />
        <TextInput source="detail_en" label="detail EN" fullWidth validate={[required()]} />
        <TextInput source="detail_ja" label="detail JA" fullWidth validate={[required()]} />
        <TextInput source="detail_zh" label="detail ZH" fullWidth validate={[required()]} />
        <TextInput source="detail_my" label="detail MY" fullWidth validate={[required()]} />
        <TextInput source="path" label="Path" fullWidth validate={[required()]} />
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceArrayInput
                        label="Main services"
                        source="main_services"
                        reference="mainservices"
                        fullWidth
                        validate={[required()]}>
                        <SelectArrayInput optionText="name_th" />
                    </ReferenceArrayInput>
                )
            }}
        </FormDataConsumer>
        <ImageInput source="image" label="Image" accept="image/*" validate={[required()]}>
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
        <BooleanInput label="visible" source="visible" />
    </SimpleForm>
);

export default Form;