import React from 'react';
import { SimpleForm, FormDataConsumer, ReferenceInput, SelectInput, BooleanInput, NumberInput } from 'react-admin';
import { required } from '../../services/validate'
import { SERVICE_TYPE } from '../../services/service-type';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Subservices"
                            source={formData.sub_service && formData.sub_service._id ? "sub_service._id" : "sub_service._id"}
                            reference="subservices"
                            fullWidth
                            validate={[required()]}
                            filter={{ main_service: { _id: SERVICE_TYPE.COVID } }}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <NumberInput source="hours" label="Hours" fullWidth validate={[required()]} />
        <NumberInput source="price" label="Price" fullWidth validate={[required()]} />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;